import { Routes } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
var ɵ0 = {
    title: 'Page 404'
}, ɵ1 = {
    title: 'Page 500'
}, ɵ2 = {
    title: 'Login Page'
}, ɵ3 = {
    title: 'Register Page'
}, ɵ4 = {
    title: 'الرئيسية'
}, ɵ5 = function () { return import("./views/base/base.module.ngfactory").then(function (m) { return m.BaseModuleNgFactory; }); }, ɵ6 = function () { return import("./views/buttons/buttons.module.ngfactory").then(function (m) { return m.ButtonsModuleNgFactory; }); }, ɵ7 = function () { return import("./views/chartjs/chartjs.module.ngfactory").then(function (m) { return m.ChartJSModuleNgFactory; }); }, ɵ8 = function () { return import("./components/list-sitters.module.ngfactory").then(function (m) { return m.ListSittersModuleNgFactory; }); }, ɵ9 = function () { return import("./components/reservation/reservation.module.ngfactory").then(function (m) { return m.ReservationModuleNgFactory; }); }, ɵ10 = function () { return import("./components/reservation-package/reservation-package.module.ngfactory").then(function (m) { return m.ReservationPackageModuleNgFactory; }); }, ɵ11 = function () { return import("./components/sitter-comments/comments.module.ngfactory").then(function (m) { return m.CommentsModuleNgFactory; }); }, ɵ12 = function () { return import("./views/dashboard/dashboard.module.ngfactory").then(function (m) { return m.DashboardModuleNgFactory; }); }, ɵ13 = function () { return import("./views/icons/icons.module.ngfactory").then(function (m) { return m.IconsModuleNgFactory; }); }, ɵ14 = function () { return import("./views/notifications/notifications.module.ngfactory").then(function (m) { return m.NotificationsModuleNgFactory; }); }, ɵ15 = function () { return import("./views/theme/theme.module.ngfactory").then(function (m) { return m.ThemeModuleNgFactory; }); }, ɵ16 = function () { return import("./views/widgets/widgets.module.ngfactory").then(function (m) { return m.WidgetsModuleNgFactory; }); };
export var routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '404',
        component: P404Component,
        data: ɵ0
    },
    {
        path: '500',
        component: P500Component,
        data: ɵ1
    },
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ2
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: ɵ3
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        data: ɵ4,
        children: [
            {
                path: 'base',
                loadChildren: ɵ5
            },
            {
                path: 'buttons',
                loadChildren: ɵ6
            },
            {
                path: 'charts',
                loadChildren: ɵ7
            },
            {
                path: 'sitter',
                loadChildren: ɵ8
            },
            {
                path: 'reservation-hourly',
                loadChildren: ɵ9
            },
            {
                path: 'reservation-package',
                loadChildren: ɵ10
            },
            {
                path: 'comments',
                loadChildren: ɵ11
            },
            {
                path: 'dashboard',
                loadChildren: ɵ12
            },
            {
                path: 'icons',
                loadChildren: ɵ13
            },
            {
                path: 'notifications',
                loadChildren: ɵ14
            },
            {
                path: 'theme',
                loadChildren: ɵ15
            },
            {
                path: 'widgets',
                loadChildren: ɵ16
            }
        ]
    },
    { path: '**', component: P404Component }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
